import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Stack, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";
import classes from "./Dashboard.module.css";
import DashAlerts from "./widgets/DashAlerts";
import DashCamerasOff from "./widgets/DashCamerasOff";
import DashFailures from "./widgets/DashFailures";
import DashTotalSites from "./widgets/DashTotalSites";
import DashNewEventsVsCamerasOff from "./widgets/DashNewEventsVsCamerasOff";
import { motion } from "framer-motion";
import AuthHeader from "../services/AuthHeader";
import { useTranslation } from "react-i18next";
import PageHeader from "../pageHeader/PageHeader";

const Dashboard = () => {
  const { t } = useTranslation();
  const [weeklyIssues, setWeeklyIssues] = useState({});
  const [fixedCameras, setFixedCameras] = useState(0);
  // const [countries, setCountries] = useState([
  //   {
  //     name: "USA",
  //     totalServers: 5,
  //     alerts: 3,
  //     camerasOff: 1,
  //     camerasFixed: 2,
  //     servers: [
  //       { serverName: "Server A" },
  //       { serverName: "Server B" },
  //       { serverName: "Server C" },
  //     ],
  //   },
  //   {
  //     name: "Canada",
  //     totalServers: 3,
  //     alerts: 1,
  //     camerasOff: 2,
  //     camerasFixed: 1,
  //     servers: [
  //       { serverName: "Server X" },
  //       { serverName: "Server Y" },
  //     ],
  //   },
  // ]);
  const [countries, setCountries] = useState([]);
  // const [sites, setSites] = useState([
  //   {
  //     name: "Site Alpha",
  //     totalServers: 4,
  //     alerts: 2,
  //     camerasOff: 1,
  //     camerasFixed: 1,
  //     servers: [
  //       { serverName: "Server 1" },
  //       { serverName: "Server 2" },
  //     ],
  //   },
  //   {
  //     name: "Site Beta",
  //     totalServers: 2,
  //     alerts: 0,
  //     camerasOff: 1,
  //     camerasFixed: 1,
  //     servers: [
  //       { serverName: "Server 3" },
  //     ],
  //   },
  // ]);
  const [sites, setSites] = useState([]);

  let groupsGet = 0;
  useEffect(() => {
    const readWeeklyEventsIssues = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        groupsGet = user.groups === null || "" ? 0 : user.groups;
      }
      let response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/readEventsIssues.php?group=${groupsGet}&timestamp=${new Date().getTime()}`
      );
      let data = await response.json();
      setWeeklyIssues(data);
    };

    readWeeklyEventsIssues();
  }, []);

  useEffect(() => {
    const getFixedCameras = async () => {
      const response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/getCamerasRepairWeek.php?timestamp=${new Date().getTime()}`
      );
      const data = await response.json();
      setFixedCameras(data[0].total);
    };

    getFixedCameras();
  }, []);

  useEffect(()=>{
    const getDashboardEvent = async () => {
      const response = await fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/cronDashboardEvents.php?group=${groupsGet}&timestamp=${new Date().getTime()}`);
      const data = await response.json();
      setCountries(data);
    };

    getDashboardEvent();
  }, []);

  useEffect(()=>{
    const getDashboardEvent = async () => {
      const response = await fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/cronDashboardEventsSites.php?group=${groupsGet}&timestamp=${new Date().getTime()}`);
      const data = await response.json();
      setSites(data);
    };

    getDashboardEvent();

  },[])

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  let isAuth = AuthHeader();
  if (isAuth === null) {
    return "";
  }

  return (
    <div className={classes.dashboardCenter}>
      <div>
        <Stack direction="row" className="flex justify-evenly">
          <Grid item xs={2} className="bgSaltexColor overflow-y-auto">
            <Navigation />
          </Grid>
          <Grid item container xs={10} className="bgPage">
            <ProfileBar />
            <PageHeader imageUrl={"assets/logos/logo_company_dark.png"}>
              <Typography className="font-size-25 font-bold">{t('Dashboard')}</Typography>
              <Typography>{t('subtitle-dashboard')}</Typography>
            </PageHeader>
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
              variants={container}
              initial="hidden"
              animate="show"
            >
              <motion.div variants={item}>
                <DashAlerts />
              </motion.div>
              <motion.div variants={item}>
                <DashCamerasOff />
              </motion.div>
              <motion.div variants={item}>
                <DashFailures />
              </motion.div>
              <motion.div variants={item}>
                <DashTotalSites />
              </motion.div>
            </motion.div>
            <div className={classes.dashSecondLine}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={6} sx={{ maxHeight: "400px", overflowY: "auto", paddingRight: 1 }}>
                  <Typography variant="h6" className="font-bold mb-4" sx={ {marginBottom: "30px", marginTop: "10px"}}>Countries</Typography>
                  {countries.map((country, index) => (
                    <Accordion key={index} sx={{ boxShadow: 3, borderRadius: 2, marginBottom: 2, width: "99%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Typography style={{ width: "100%", fontWeight: "bold" }}>{country.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 2, backgroundColor: "#fff", textAlign: "left" }}>
                        <Typography>Total Servers: {country.totalServers}</Typography>
                        <Box display="flex" gap={2} marginBottom={1}>
                          <Typography sx={{ color: "#ffc107", fontWeight: "bold" }}>Alerts: {country.alerts}</Typography>
                          <Typography sx={{ color: "#f44336", fontWeight: "bold" }}>Cameras Off: {country.camerasOff}</Typography>
                          <Typography sx={{ color: "#2196f3", fontWeight: "bold" }}>Cameras Fixed: {country.camerasFixed}</Typography>
                        </Box>
                        <Typography className="mt-2 font-bold">Servers:</Typography>
                        <ul style={{ paddingLeft: "1.5rem", marginTop: 0 }}>
                          {country.servers.map((server, serverIndex) => (
                            <li key={serverIndex} style={{ paddingBottom: "4px" }}>{server.serverName}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>

                <Grid item xs={6} sx={{ minHeight: "400px", maxHeight: "400px", overflowY: "auto", paddingLeft: 1 }}>
                  <Typography variant="h6" className="font-bold mb-4" sx={ {marginBottom: "30px", marginTop: "10px"}}>Sites</Typography>
                  {sites.map((site, index) => (
                    <Accordion key={index} sx={{ boxShadow: 3, borderRadius: 2, marginBottom: 2, width: "99%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Typography style={{ width: "100%", fontWeight: "bold" }}>{site.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 2, backgroundColor: "#fff", textAlign: "left" }}>
                        <Typography>Total Servers: {site.totalServers}</Typography>
                        <Box display="flex" gap={2} marginBottom={1}>
                          <Typography sx={{ color: "#ffc107", fontWeight: "bold" }}>Alerts: {site.alerts}</Typography>
                          <Typography sx={{ color: "#f44336", fontWeight: "bold" }}>Cameras Off: {site.camerasOff}</Typography>
                          <Typography sx={{ color: "#2196f3", fontWeight: "bold" }}>Cameras Fixed: {site.camerasFixed}</Typography>
                        </Box>
                        <Typography className="mt-2 font-bold">Servers:</Typography>
                        <ul style={{ paddingLeft: "1.5rem", marginTop: 0 }}>
                          {site.servers.map((server, serverIndex) => (
                            <li key={serverIndex} style={{ paddingBottom: "4px" }}>{server.serverName}</li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              </Stack>
            </div>
          </Grid>
        </Stack>
      </div>
    </div>
  );
};

export default Dashboard;
